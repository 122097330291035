import { NgModule } from '@angular/core';
import {
  OWL_DATE_TIME_LOCALE,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';

@NgModule({
  imports: [OwlDateTimeModule, OwlNativeDateTimeModule],
  providers: [{ provide: OWL_DATE_TIME_LOCALE, useValue: navigator.language }],
})
export class DatepickerModule {}
